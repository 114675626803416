@import "../../utils//colors.utils.scss";

.custom-input {
    background-color: transparent;
    font-size: 1em;
    padding-inline: 0.5em;
    border: none;
    outline: none;
    position: relative;

    &.box {
        max-width: 300px;
        background-color: $color-background;
        border-radius: 0.5em;
        border: 1px solid $color-text;

        &::placeholder {
            color: $color-darker-gray;
        }
    }

    &.underline {
        padding-left: 0;
        border-bottom: 1px solid currentColor;
    }

    &.invisible {
        background-color: transparent;
        padding-left: 0;
    }
    &::placeholder {
        color: $gray-placeholder;
    }
}
