@import "../../../../../utils/colors.utils.scss";

.rich-text-editor-container {
    .editor {
        position: relative;
        .ck {
            background-color: #f2e9ea;
            border: 0;
            outline: none;
        }

        .ck-editor__top {
            position: sticky;
            top: 0;
            border-bottom: 1px solid $color-text;
        }

        .ck-focused {
            border: none !important;
            box-shadow: none !important;
        }

        .ck-editor {
            border-color: pink;
            border-left: $color-foreground;
        }

        .ck-editor__editable_inline {
            height: 27rem;
        }
    }
    .ticket-bottom {
        height: 5.5vh;

        .attachment-button {
            height: 42px;
            aspect-ratio: 1;
            background-color: $color-gray;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            & > * {
                font-size: 24px;
                transform: rotate(-45deg);
            }
        }
        .editor-files {
            display: inline-flex;
            flex-wrap: wrap;
            overflow-y: auto;
            .file-container {
                width: 30%;
                font-size: 0.8em;
                height: 90%;
                display: grid;
                grid-template-columns: 92% 8%;
                align-items: center;
                justify-content: space-between;
                background-color: $dark-red;
                padding: 0.5rem 1rem;
                border-radius: 10px;
                color: $color-white;
                .file-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // if overflows, break the word to 2nd line
                    word-break: break-all;
                    // max 2 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .cancel-btn {
                    width: 1.2rem;
                    height: 1.2rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
