@import "../../../utils/colors.utils.scss";

.sidebar-button-wrapper {
	width: 100%;
	padding-left: min(16%, 1rem);

	.sidebar-button {
		height: 2.4rem;
		display: flex;
		// justify-content: center;
		align-items: center;
		padding-left: 1.2rem;
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		margin-block: 1.2vh;

		.sidebar-icon {
			font-size: 0;

			& svg {
				width: 1.4rem;
				height: 1.4rem;
			}

			&.selected {
				path {
					fill: $color-background-bars;
				}
			}
		}

		&.selected {
			position: relative;
			background-color: $color-foreground;

			// &::after {
			// 	background-color: $color-foreground;
			// 	content: "";
			// 	z-index: 0;
			// 	position: absolute;
			// 	inset: 0;
			// 	left: 20%;
			// 	border-top-left-radius: 0.5rem;
			// 	border-bottom-left-radius: 0.5rem;
			// }
		}
	}
}
