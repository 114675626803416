@import "../../../../../../../../utils/colors.utils.scss";

.lock-progress-container {
    width: 50%;
    justify-content: space-between;

    .lock-icon {
        font-size: 1.5em;

        &.locked {
            color: $color-green;
        }
    }

    .lock-box {
        $height: 2.1em;
        height: $height;
        flex: 1;
        background-color: $color-gray;
        border-radius: $height * 0.3;

        &.locked {
            background-color: $color-light-green;
        }

        &.filled {
            justify-content: flex-start;
            padding-left: 1em;
        }
    }
}
