@import "../../../../../../utils/colors.utils.scss";

.company-information-container {
    .image {
        max-width: max-content;
        height: 8rem;
        aspect-ratio: 1;
        border-radius: 1rem;
        overflow: hidden;
        background-color: blue;
    }
}
