@import "../../../../../utils/colors.utils.scss";

.message-box {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 50%;
    // max-width: min(32vw, 300px);
    padding: 0.8vh 0.8em;
    border-radius: 0.8vh;
    margin-bottom: 1.2vh;
    font-size: min(1.6vw, 16px);

    .text {
        width: 100%;
        word-wrap: break-word;
        padding-bottom: 0.2em;
    }

    .time {
        position: relative;
        font-size: 0.6em;
        top: 1em;
        float: right;
        margin-left: 0.5em;
        margin-right: -0.3em;
    }

    &.file {
        background-color: #bbb;
    }

    .message-name {
        width: max-content;
        height: fit-content;
        margin-left: auto;
        border-radius: 0.8vh;
        opacity: 0.6;
        padding: 0.1em 0.6em;
        font-size: 0.8em;
        margin-bottom: 0.5em;
        &.my-message {
            margin-right: auto;
            margin-left: 0;
        }
    }
}

.my-message {
    background-color: $color-light-gray;
    align-self: flex-end;
    // margin-left: auto;
}

.others-message {
    background-color: $color-dark-gray;
    align-self: flex-start;
    // margin-right: auto;
}

.download-img {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
}
