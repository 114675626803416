@import "../../../../utils/colors.utils.scss";

.row-data-container {
    width: 100%;
    height: 39.2rem;
    display: flex;
    flex-direction: column;
    padding-left: 1.2rem;

    .tab-bar {
        color: $color-text;
        display: grid;
        grid-template-columns: 4fr repeat(4, 3fr) 4rem;
        border-bottom: 1px solid currentColor;
        margin-block: 0.5rem 1rem;

        .tab-button {
            padding-block: 0.5rem;

            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            &.selected {
                border-bottom: 2px solid $color-foreground;
            }
        }
    }

    .tab-container {
        padding-right: 1rem;
        height: 84%;
        overflow: auto;
    }
}
