@import "../../utils/colors.utils.scss";

.topbar-container {
    background-color: $color-background;
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-inline: 2vw;
    border-bottom: 3px solid $color-foreground;

    .left-side-container,
    .right-side-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        gap: 10px;

        .logo-container {
            display: flex;
            align-items: center;
            height: 100%;
            padding-right: 1.5vw;
            border-right: 3px solid $color-foreground;
            .meti-logo {
                height: 35%;
                width: auto;
            }
        }
        .cd-logo-container {
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 1vw;
            .cd-logo {
                height: 70%;
                width: auto;
            }
        }
    }
}
