@import "../../../utils/colors.utils.scss";

.tickets-container {
	.tabs-wrapper {
		flex: 1;
		display: grid;
		grid-template-columns: 1fr 1fr;

		.selected-tab {
			color: $color-foreground;
			border-bottom: 2.1px solid currentColor;
		}
		span {
			// &.selected-tab {
			// 	color: $color-foreground;
			// 	border-bottom: 2px solid currentColor;
			// }
		}
	}

	.empty-message {
		color: $color-darker-gray;
	}
}
