@import "../../../../../utils/colors.utils.scss";

.filter-modal-container {
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1;

	.modal {
		top: 20%;
		right: 10%;
		background-color: $color-background;

		.filter-instance-wrapper {
			border-color: $color-dark-gray;
		}

		.clear {
			background-color: #f9ddde;
			color: #c20911;
			flex: 1;
		}
		.filter {
			background-color: #c20911;
			color: #f5f5f5;
			flex: 1;
		}

		.selected {
			color: #c20911;
		}
	}
}
