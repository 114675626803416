@import "../../../../../utils/colors.utils.scss";

.ticket-table-container {
    overflow: auto;
    max-height: 80vh;

    table {
        border-collapse: collapse;
        width: 100%;
        position: relative;
        border-spacing: 0;

        th,
        td {
            padding-bottom: 0.4rem;
        }

        thead {
            position: sticky;
            top: 0;
            background-color: $hover;
            box-shadow: 0 1px black;
            th {
                height: 100%;
                padding: 0.6rem 0;
            }
        }

        tbody {
            tr {
                border-color: $color-dark-gray;

                & > td {
                    padding-top: 1rem;
                }
            }
        }
    }
}

.ticket-checkbox {
    padding-right: 1rem;
}
