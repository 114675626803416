@import "../../../../../utils/colors.utils.scss";

.input-container {
    display: flex;
    align-items: center;
    width: 100%;
    // height: 100px;
    padding-top: 0.5vh;
    padding-bottom: 4vh;

    .attachment-button {
        height: 42px;
        aspect-ratio: 1;
        background-color: $color-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        & > * {
            font-size: 24px;
            transform: rotate(-45deg);
        }
    }

    .text-area {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
        resize: none;
        outline: none;
        border-radius: 10px;
        padding: 9px 10px;
        border: 1px solid $color-black;
        margin-inline: 2vw;

        &::-webkit-resizer {
            display: none;
        }

        /* width */
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #00000080;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .send-button {
        height: 42px;
        aspect-ratio: 1;
        background-color: $color-pink;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        & > * {
            margin-right: 1px;
            font-size: 21px;
        }
    }
    .cancel-btn {
        margin-left: 48px;
    }
}

.disabled {
    opacity: 0.5;
    background-color: #bbb;
    cursor: not-allowed;
}
