@import "../../../utils/colors.utils.scss";

.trash-container {
	padding-left: min(2vw, 24px);
	overflow: hidden;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 0.3rem;
		border-bottom: 1px solid $color-black;
		margin-top: 1em;
		gap: 5vw;

		.header-search-form {
			display: flex;
			flex: 1;
			border-bottom: 1px solid $color-black;
			margin-bottom: 5px;
			padding-inline: 10px;

			.header-search {
				width: 100%;
				border: 0;
				outline: none;
				font-size: 0.8em;
				padding-bottom: 5px;
			}

			button {
				font-size: 1.2em;
				border: 0;
				background: none;
			}
		}

		.buttons-container {
			display: flex;
			gap: 1vw;
			padding-right: 2vw;

			.disabled {
				cursor: default;
				color: $color-dark-gray;
				border-color: $color-dark-gray;
			}

			button {
				cursor: pointer;
				background-color: transparent;
				padding-inline: 1.2em;
				padding-block: 0.2em;
				text-align: center;
				font-size: 0.8em;
				font-weight: 500;
				border-radius: 0.5em;
				border: 0.12em solid $color-black;
			}
		}
	}

	.warning-container {
		display: flex;
		align-items: center;
		width: 95%;
		gap: 1em;
		padding-block: 1.2em;

		.line {
			flex: 1;
			height: 1px;
			width: 100px;
			background-color: $color-red;
		}

		.warning-message {
			font-size: 0.72em;
			color: $color-red;
			font-weight: 500;

			.bold {
				font-weight: 700;
				text-decoration-line: underline;
			}
		}
	}
}
