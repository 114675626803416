@import "../../../../../../utils/colors.utils.scss";

.info-tab-container {
    height: 80%;
    display: flex;
    flex-direction: column;

    .company {
        .img-box {
            height: 8rem;
            width: 8rem;
            border-radius: 0.8rem;
            background-color: $color-foreground;
        }

        .img-circle {
            height: 8rem;
            width: 8rem;
            border-radius: 50%;
            border: 0.8rem solid transparent;
        }

        .fields {
            font-size: 0.8rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 2rem;
            row-gap: 1.5rem;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;

            .grid {
                display: grid;
                grid-template-columns: 16ch 1fr;

                .title {
                    font-weight: 600;
                }
            }
        }
    }
}
