@import "../../../../../utils/colors.utils.scss";

$grid-column-template: min-content 3fr 4fr 2fr 2fr;

.trash-table-container {
	overflow: auto;
	width: 100%;

	.trash-table {
		width: 95%;
		border-spacing: 0;
		font-size: 16px;

		thead {
			background-color: $color-lilac;

			.table-row {
				height: 2.4em;
				font-weight: 400;
				text-align: left;
				align-items: center;
				display: grid;
				grid-template-columns: $grid-column-template;
				font-size: 1em;
				padding-inline: 10px;
			}
		}

		tbody {
			width: calc(100% + 20px);
			font-size: 0.8em;
			max-height: 72vh;
			display: block;
			overflow-y: auto;
			overflow-x: hidden;

			.table-row {
				width: calc(100% - 40px);
				height: 3em;
				font-weight: 400;
				text-align: left;
				display: grid;
				align-items: center;
				grid-template-columns: $grid-column-template;
				font-size: 0.9em;
				padding-inline: 10px;
			}

			& > * {
				margin-right: 12px;
			}

			/* width */
			&::-webkit-scrollbar {
				width: 8px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: transparent;
				padding-inline: 10px;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #00000080;
				border-radius: 10px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}

			.last-child {
				border-bottom: 0;
			}
		}

		.table-checkbox {
			font-size: 16px;
		}
	}
}
