@import "../../../utils/colors.utils.scss";

.sidebar-folder-instance-container {
	cursor: pointer;
	display: flex;
	min-width: 12em;
	padding-block: 0.6em;
	padding-inline: 1em;
	align-items: center;
	// font-size: 0.5rem;

	.folder-icon {
		width: 1.6em;
		height: 1.6em;
	}

	.folder-name {
		margin-left: 1em;
	}
}

.choosen-folder {
	background-color: $color-pink;
	border-radius: 0.5em;
}
