@import "../../../../../../../../utils/colors.utils.scss";

.people-sub-category-container {
    overflow: visible !important;
    .person {
        display: grid;
        grid-template-columns: 2.7rem 20ch 1fr 16ch 12ch;
        align-items: center;
        gap: 1rem;

        border-bottom: 1px solid $color-dark-gray;

        .img {
            height: 2.4rem;
            aspect-ratio: 1;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid $color-text;
        }

        button {
            color: $color-red;
            padding-block: 0.4em;
            border-radius: 0.8em;
            border: 1px solid currentColor;

            &:hover {
                background-color: $color-lighter-gray;
            }
        }
    }
}

.xx {
    position: absolute;
    z-index: 1000;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-dark-gray;
    opacity: 0.5;
}

.modal-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}
