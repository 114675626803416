@import "../../../../../../../../utils/colors.utils.scss";

.info-sub-category-container {
	height: 90%;

	.grid {
		height: 85%;
		display: grid;
		grid-template-columns: 2fr 3fr;
		gap: 1.6rem;
		padding-top: 1rem;

		.left-side {
			.image {
				height: 7rem;
				aspect-ratio: 1;
				border-radius: 50%;
				border: 0.5rem solid $color-text;
			}

			.info {
				margin-bottom: 10px;
			}

			.with-line::after {
				margin-top: 0;
			}
		}
		.right-side {
			.red {
				color: $color-foreground;
			}

			.selected-client {
				color: $color-red;
			}

			.container-m {
				height: 30%;
			}

			.image {
				height: 100%;
				aspect-ratio: 1;
				border-radius: 0.8rem;
				border: 1px solid $color-text;
			}
		}
	}
	button {
		width: 9ch;
		height: 1.6em;
		border: 1px solid currentColor;
		border-radius: 1.5rem;

		&:hover {
			background-color: $color-lighter-gray;
		}

		&.cancel {
			color: $color-red;
		}
	}
}
