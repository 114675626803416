@import "../../../../../../utils/colors.utils.scss";

.notes-container {
	.ck {
		border: 0;
		outline: none;
	}

	.ck-editor__top {
		position: sticky;
		top: 0;
		border-bottom: 1px solid $color-text;
	}

	.ck-focused {
		border: none !important;
		box-shadow: none !important;
	}

	.ck-editor {
		border-color: pink;
	}

	.ck-editor__editable_inline {
		min-height: 14rem;
	}
}
