@import "../../../../../../utils//colors.utils.scss";

.my-account-container {
	.image-profile {
		// max-width: fit-content;
		min-width: 4.5rem;
		min-height: 4.5rem;
		// aspect-ratio: 1;
		// border: 1px solid $color-black;
		// border-radius: 50%;
		// overflow: hidden;
	}

	button {
		cursor: pointer;
		font-size: 0.7rem;
		max-width: max-content;
		background: transparent;
		padding: 0.12em 0.8em;
		border-radius: 0.5em;
		border: 0.12em solid $color-black;
		margin-top: 1em;
		outline: none;
	}
}
