.question-container {
    .question-mark {
        width: 3rem;
        height: auto;
    }
    max-width: 30vw;
    min-height: 20vh;
    background-color: white;
    line-height: 1.5rem;
    text-align: center;
}
