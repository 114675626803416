@import "../../utils/colors.utils.scss";

@keyframes szh-menu-show {
	from {
		opacity: 0;
	}
}

@keyframes szh-menu-hide {
	to {
		opacity: 0;
	}
}

.szh-menu {
	z-index: 100;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 0.72rem;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	border: 1px solid rgba(0, 0, 0, 1);

	font-family: sans-serif;
	font-size: 0.925rem;
	user-select: none;
	color: $color-background;

	padding: 6px;
	min-width: 10rem;

	&--state-opening {
		animation: szh-menu-show 0.15s ease-out;
	}

	&--state-closing {
		animation: szh-menu-hide 0.2s ease-out forwards;
	}

	&__item {
		border-radius: 6px;
		padding: 0.375rem 0.625rem;
		&--hover {
			color: #fff;
			background-color: $primary-red;
		}
		&--submenu {
			position: relative;
			&::after {
				content: ">";
				// content: url("chevron-right-solid.svg");
				position: absolute;
				width: 7px;
				right: 0.625rem;
			}
		}
	}

	&__divider {
		margin: 0.5rem 0.625rem;
	}
}
