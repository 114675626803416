@import "../../../../../../utils/colors.utils.scss";

.people-container {
    .person-wrapper {
        position: relative;

        &.expanded-title {
            &::after {
                content: "";
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                border-left: 4px solid $color-foreground;
            }
        }

        &:hover,
        &:focus-visible {
            background-color: $hover;
        }
    }

    .accordion-data {
        grid-template-columns: 1fr !important;
        align-items: flex-start;
        height: 0;
        transform: scale(0);
        transform-origin: 0;
        transition: transform 200ms ease-in-out, height 200ms ease-in-out;
        border-color: $color-foreground !important;

        &.expanded {
            position: relative;
            // height: min-content;
            height: 45vh;
            transform: scale(1);
            border-bottom: 1px solid $color-foreground;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-left: 4px solid $color-foreground;
            }
        }
    }
}
