@import "../../utils/colors.utils.scss";

.custom-select {
    height: 2.4em;
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0.2rem 0.5rem;
    border: 1px solid $color-black;
    border-radius: 10px;
    overflow: hidden;

    select {
        cursor: pointer;
        appearance: none;
        width: 100%;
        font-size: 1rem;
        background-color: transparent;
        border: none;
        outline: none;

        &::-ms-expand {
            display: none;
        }

        &:focus + .focus {
            position: absolute;
            inset: -1px;
            border: 2.4px solid $color-purple;
            border-radius: inherit;
        }
    }

    &.disabled > select {
        text-align: center;
    }

    select,
    &::after {
        grid-area: select;
    }

    &:not(.disabled)::after {
        content: "";
        display: relative;
        width: 0.8em;
        height: 0.5em;
        background-color: $color-black;
        clip-path: polygon(0 50%, 10% 40%, 50% 80%, 90% 40%, 100% 50%, 50% 100%);
        justify-self: end;
    }
}
