@import "../../../../../utils/colors.utils.scss";

.chat-box {
	width: 100%;
	position: relative;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 2em 3fr 1fr;
	align-items: center;
	gap: 0.5em;
	padding: 12px 10px;
	border-radius: 12px;

	.left {
		width: 2em;
		// min-width: 2em;
		aspect-ratio: 1;
		border-radius: 50%;
		border: 1px solid $color-black;
		overflow: hidden;
	}

	.middle {
		overflow: hidden;
		white-space: nowrap;

		.name {
			color: $color-black;
			font-weight: 400;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.message {
			font-size: 1em;
			color: $color-darker-gray2;
			font-weight: 400;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		font-size: 0.7em;
		row-gap: 4px;

		.date {
		}
		.unreads {
			text-align: center;
			width: calc(min(1.2vw, 12px) + 10px);
			aspect-ratio: 1;
			background-color: $color-lighter-red;
			line-height: calc(min(1.2vw, 12px) + 10px);
			color: $color-background;
			border-radius: calc((min(1.2vw, 12px) + 10px) / 4);
		}
	}
}

.chat-box.selected {
	background-color: $color-light-pink;
}
