.advertise {
    padding-left: 1%;
    padding-top: 2%;
    display: flex;
    // flex-direction: column;
    justify-content: flex-start;
    gap: 1vw;
    width: 95%;
    height: auto;
    z-index: 200;

    .column-ads {
        display: flex;
        flex-direction: column;
        gap: 8vh;
    }
    .ad-image {
        max-width: 42vw;
        max-height: 60vh;
        object-fit: contain;
        border-radius: 1.2vw;
    }
    .ad-image-remove {
        margin-top: 1rem;
        padding: 0.5rem;
        width: fit-content;
    }
    .label-advertise {
        margin-right: 2vw;
        margin-bottom: 2vh;
    }
    .text {
        width: 35vw;
        height: 3vh;
    }
    .save-ads-btn {
        width: 8vw;
        height: 3vh;
    }
}
