@import "../../utils/colors.utils.scss";

.table-container {
    margin-top: 25px;
    margin-bottom: 1vh;
    width: 94%;
    // height: 55vh;
    flex: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    user-select: none;

    &::-webkit-scrollbar {
        display: none;
    }
    .grid {
        display: grid;
        grid-template-columns: 3ch 18ch 2.2fr 1fr 2ch;
        gap: 3vw;
    }

    .header {
        background-color: $color-lilac;
        border-bottom: 2px solid $color-text;
    }

    .body {
        padding-right: 1rem;
        margin-right: -1rem;
        overflow-x: visible;
        overflow-y: auto;
        // height: 80%;
        flex: 1;
        & > div > *:not(:last-of-type) {
            border-bottom: 1px solid $color-dark-gray;
        }

        .body-item {
            background-color: $color-background;
            transition: scale 200ms ease-in-out;
            transform-origin: left;

            &.selected-row {
                z-index: 1;
                scale: 1.02;
            }

            .drag-handler {
                position: relative;

                .hover-menu {
                    $height: 2.7rem;

                    background-color: $color-black;
                    height: $height;
                    width: max-content;
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    translate: 0 (-$height / 2);
                    display: none;

                    .line {
                        color: $gray-default;
                        .first-word {
                            color: $color-background;
                        }
                    }
                }
            }

            .wrap {
                flex-wrap: wrap;
                word-wrap: break-word;
            }

            &:not(.selected-row) {
                .handle:hover + .hover-menu {
                    display: flex;
                }
            }

            .ref-input {
                border: 2px solid transparent;
                border-radius: 5px;
                outline: none; /* remove the default focus outline */
                transition: border-color 0.2s ease-in-out;
                width: 80%;
                &:hover {
                    border-color: #666;
                }
                &:focus {
                    border-color: #666;
                }
            }
            .filename-input {
                border: 2px solid transparent;
                border-radius: 5px;
                outline: none; /* remove the default focus outline */
                transition: border-color 0.2s ease-in-out;
                width: 90%;
                &:hover {
                    border-color: #666;
                }
                &:focus {
                    border-color: #666;
                }
            }
        }
    }

    .footer {
        background-color: $color-lilac;
        border-top: 2px solid $color-text;
    }

    .table {
        width: 100%;
        // max-width: 900px;
        overflow: hidden;

        table {
            border-spacing: 0;
            width: 100%;

            tr {
                & > * {
                    padding-block: 0.5vh;
                    padding-left: 4%;
                }
            }

            th {
                text-align: left;
                background-color: $color-lilac;
                font-size: 16px;
                font-weight: 500;
                border-bottom: 1px solid $color-black;
            }

            td {
                border-bottom: 1px solid $color-black;
                cursor: pointer;
            }

            .table-footer {
                background-color: $color-lilac;

                td {
                    border: 0;
                    font-weight: 600;
                }
            }
        }

        .new-file-button {
            display: flex;
            padding: 0;
            background-color: $color-lilac;

            .text {
                cursor: pointer;
                padding-inline: 4%;
                height: 100%;
                padding-block: 0.5vh;
                font-weight: 500;
            }
        }
    }
}

.sort-button {
    color: $color-darker-gray2;
    height: 100%;
    width: 100%;

    &.none {
        color: $color-dark-gray;
    }
}
