@import "../../utils/colors.utils.scss";

.warning-box-container {
    width: max-content;
    max-width: 50vw;

    &.error {
        background-color: $secondary-shade;
        color: $primary-red;
    }

    &.done {
        background-color: #f0ffe0;
        color: #58901f;
    }
}
