@import "../../../../../../../../utils/colors.utils.scss";

.person-container {
	display: flex;
	align-items: center;
	gap: 1rem;

	.name-wrapper {
		width: 36ch;
	}

	.user-type {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 13ch;
		// height: 2em;
		border-radius: 0.5em;
		border: 1px solid $color-black;
	}

	// button {
	// 	background-color: $color-gray;
	// 	height: 2em;
	// 	padding-inline: 1.15em;
	// 	border-radius: 0.5em;
	// }

	:nth-child(4) {
		margin-left: auto;
	}
}
