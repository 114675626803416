@import "../../utils/colors.utils.scss";

.move-file-modal-container {
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.modal {
		z-index: 2;
		background-color: $color-background;
		width: min(40%, 800px);
		height: min(40%, 500px);

		.modal-header {
			border-bottom: 2px solid $color-foreground;

			// ion-icon[name="arrow-back-outline"] {
			// 	font-size: 1.6rem;
			// }
		}

		.folders-container {
			height: 60%;
			flex-wrap: wrap;
			overflow: auto;
		}
	}
}
