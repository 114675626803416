@import "../../utils/colors.utils.scss";

.new-client-modal-container {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal {
        z-index: 2;
        background-color: $color-background;
        width: min(80%, 1000px);
        height: min(70%, 600px);

        .modal-header {
            border-bottom: 2px solid $color-foreground;
        }
    }
    button[disabled] {
        color: rgba($black, 0.18);
        cursor: not-allowed;
    }
}

.page-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    .grid {
        display: grid;
        grid-template-columns: 2fr 3fr;
    }

    .btn {
        $width: 24ch;

        background-color: transparent;
        border: 1px solid $color-text;
        max-width: $width;
        box-sizing: border-box;

        &--selected {
            color: $color-background;
            background-color: $color-foreground;
            border-color: $color-foreground;
        }
    }
}

.page1 {
    $width: 16ch;
    .btn {
        width: $width;
    }
}

.page2-1,
.page-personal-2 {
    .image {
        width: 6rem;
        aspect-ratio: 1;
        border-radius: 0.8rem;
        border: 1px solid $color-text;
    }

    .btn {
        text-align: left;
    }
}

.page2-1-personal {
    $width: 5rem;
    .image {
        width: $width;
        aspect-ratio: 1;
        font-size: calc($width * 0.4);
        font-weight: 600;
        border-radius: 50%;
        border: calc($width / 10) solid $color-text;
    }
}

.page3,
.page5 {
    .btn {
        border-color: $color-foreground;
    }
}

.page3-1 {
    $width: 5rem;

    .image-wrapper {
        width: $width;
        aspect-ratio: 1;
        font-size: calc($width * 0.4);
        font-weight: 600;
        border-radius: 50%;
        border: calc($width / 10) solid $color-text;
    }
    // .image-wrapper {
    // 	width: 5rem;
    // 	aspect-ratio: 1;
    // 	border-radius: 50%;
    // 	border: 1px solid $color-text;
    // 	overflow: hidden;
    // }
}
.page4-1 {
    $width: 5rem;

    .image-wrapper {
        width: $width;
        aspect-ratio: 1;
        font-size: calc($width * 0.4);
        font-weight: 600;
        border-radius: 50%;
        border: calc($width / 10) solid $color-text;
    }

    // .image-wrapper {
    // 	width: 5rem;
    // 	aspect-ratio: 1;
    // 	border-radius: 50%;
    // 	border: 1px solid $color-text;
    // 	overflow: hidden;
    // }
}
