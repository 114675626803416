@import "../../utils/colors.utils.scss";

.login-container {
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-left-side {
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 3px solid $color-foreground;
    }

    .login-right-side {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        padding-top: 56vh;

        .login-form-container {
            display: flex;
            flex-direction: column;
            width: 60%;

            .title {
                color: $color-black;
                font-weight: 500;
                font-size: 1.6vw;
                margin-bottom: 5vh;
            }

            .text-box {
                border: 2px solid $color-foreground;
                background-color: transparent;
                width: 100%;
                aspect-ratio: 8.5;
                padding-inline: 1.6vw;
                box-sizing: border-box;
                font-size: 1.2vw;
                outline: none;
                border-radius: 0.6vw;
                margin-bottom: min(10px, 1.6vh);

                &::placeholder {
                    color: $color-darker-gray;
                }
            }

            .forgot-password {
                color: $color-black;
            }

            .vi__wrapper {
                width: 100%;
                aspect-ratio: 8.5;
            }

            .verification-container {
                height: 100%;
                width: 100%;
                margin-bottom: min(10px, 1.6vh);
            }

            .verification-character {
                outline: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.6rem;
                font-weight: 600;
                background-color: transparent;
                border: 3px solid $color-foreground;
                border-radius: 0.8rem;

                &--selected {
                    color: $color-text;
                    background-color: $secondary-shade;
                }
                &--inactive {
                    color: $color-darker-gray;
                }
            }

            .error {
                background-color: $color-light-pink;
            }
        }
    }
}

.go-to-login {
    color: $secondary-red;
}

.password-container {
    width: 100%;
    aspect-ratio: 8.5;
    display: flex;
}

.eye-container {
    height: 100%;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.eye-container:hover {
    background-color: $color-gray;
}

.eye {
    position: absolute;
    left: 50%;
    transform: translate(-130%, 55%);
    height: 40%;
}

.ads {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    padding-bottom: 8vh;
    padding-inline: 2vw;
    span {
        max-width: 85%;
        text-align: justify;
        font-size: 1vw;
    }
}

.ad-img {
    max-width: 42vw;
    max-height: 60vh;
    object-fit: contain;
    border-radius: 1.2vw;
}
