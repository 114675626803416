@import "../../utils/colors.utils.scss";

.app-container {
    display: grid;
    grid-template-columns: min(8vw, 80px) 1fr;
    // grid-template-columns: min(30vw, 320px) 1fr;
    height: 92vh;

    .sidebar {
        background-color: $color-background-bars;
        border-right: 4px solid $color-foreground;
        display: flex;
        height: 100%;
        flex: 1;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }
}
