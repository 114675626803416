@import "../../../../../../utils/colors.utils.scss";

.archive-tab-container {
    height: 100%;
    padding-top: 0.1rem;
    .sticky {
        background-color: $color-background;
        position: sticky;
        top: 0;
    }

    .selector-wrapper {
        width: 14rem;
    }

    .fixed-height {
        display: flex;
        height: 3rem;
    }

    .home-header {
        width: 100%;
        // align-items: center;
        height: 2.2rem;
        padding-bottom: 0.7rem;
        border-bottom: 1px solid $color-black;

        ion-icon[name="arrow-back-outline"] {
            cursor: pointer;
            font-size: 1em;
        }

        .name-bar {
            height: 100%;
            .lock-btn {
                height: 100%;
                width: auto;
            }
        }
    }
    .folders-container {
        width: 100%;
    }
}
