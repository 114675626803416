@import "../../utils/colors.utils.scss";

.pdf-viewer-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);

    .pdf-viewer-topbar {
        height: 8vh;
        width: 100%;
        padding-inline: 2vw;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        background: rgba(0, 0, 0, 0.6);
        border-bottom: 1px solid $color-pink;
        backdrop-filter: blur(24px);

        .left-side {
            display: inline-flex;
            gap: 24px;
            flex: 1;
            justify-content: flex-start;
            // color: $color-background;

            .title {
                font-size: 1.2vh;
                color: $color-background;
            }

            .body {
                font-size: 1.5vh;
                color: $color-background;
            }
        }

        .center {
            display: inline-flex;
            flex-direction: column;
            color: $color-background;
            flex: 1;
            justify-content: center;
            align-items: center;
            font-size: 1.4vh;
        }

        .right-side {
            display: inline-flex;
            gap: 24px;
            flex: 1;
            justify-content: flex-end;
            align-items: center;

            & > * {
                cursor: pointer;
            }

            .zoom-icon {
                font-size: 3vh;
                color: $color-background;
            }
        }
    }

    .pdf-viewer-bottombar {
        position: relative;
        // overflow: scroll;
        width: 100%;

        .file-count-container {
            position: absolute;
            bottom: 8vh;
            left: 3vw;
            height: 3.6vh;
            width: fit-content;
            color: $color-background;
            letter-spacing: 0.5px;
            line-height: 3.6vh;
            font-size: 1.6vh;
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(24px);
            padding-inline: 36px;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
            border: 1px solid $color-pink;
            border-bottom: 0;
        }
    }

    .pdf-viewer {
        // position: relative;
        display: flex;
        justify-content: center;
        // overflow: scroll;
        overflow-x: hidden;
        height: 100%;
        min-width: 60vw;
        width: fit-content;
        margin: 0 auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
}

.pdfviewer .viewer-layout-toolbar {
    background: #000;
    color: #fff;
}

.pdfviewer .viewer-icon {
    stroke: #fff;
    stroke-width: 3px;
}

.pdfviewer .viewer-zoom-popover-target-arrow {
    border-color: "blue";
    // border-color: rgb(255 255 255 / 90%) transparent transparent;
}

.pdfviewer .viewer-menu-item-label,
.pdfviewer .viewer-search-popover-label,
.pdfviewer .viewer-menu,
.pdfviewer .viewer-menu .viewer-icon {
    color: #000;
    stroke: #000;
}
