@import "../../utils/colors.utils.scss";

$height: 1.6rem;
$padding: $height / 8;
$aspect-ratio: 1.6;

.toggle-container {
    cursor: pointer;
    position: relative;
    background-color: $color-text;
    height: $height;
    aspect-ratio: $aspect-ratio;
    border-radius: $height / 2;
    // outline: 1px solid $color-text;

    .toggle {
        position: absolute;
        background-color: $color-background;
        height: 100%;
        aspect-ratio: 1;
        left: 0;
        transition: transform 300ms ease;
        border: $padding solid $color-text;
        border-radius: 50%;

        &.checked {
            transform: translateX($height * 0.6);
            background-color: $color-light-green;
        }
    }
}
