@import "../../../../../../../../utils/colors.utils.scss";

.people-sub-category-container {
	.button-container {
		width: 16rem;

		.btn-delete {
			color: $color-foreground;
		}

		button:hover {
			background-color: $color-lighter-gray;
		}
	}
}
