@import "../../utils/colors.utils.scss";

.accountant-table-container {
    max-width: 95vw;
    height: 100%;
    overflow-x: hidden;

    .empty-table {
        color: $color-darker-gray;
    }

    table {
        width: 90%;
        height: 100%;
        color: $color-text;
        border-spacing: 0;

        tbody {
            $scroll-bar-margin: 1rem;

            // max-height: 62vh;
            max-height: 80vh;
            display: block;
            overflow-y: auto;
            // overflow-x: hidden;
            padding-right: $scroll-bar-margin;
            margin-right: -$scroll-bar-margin;
        }

        tfoot {
            tr {
                border-top: 1px solid currentColor;
                padding-block: 0.5em;
            }
        }

        .with-delete {
            & > tr {
                grid-template-columns: 1fr 1fr 16rem 3rem;

                & > td:last-child {
                    display: flex;
                    justify-content: center;
                    color: $color-darker-gray;
                }
            }
        }

        tr {
            display: grid;
            text-align: left;
            grid-template-columns: 1fr 1fr 16rem;
            padding-block: 1em 0.2em;

            th,
            td {
                display: flex;
                align-items: center;
                font-size: inherit;
                font-weight: inherit;
                gap: 0.5rem;
            }

            th {
                border-bottom: 1px solid currentColor;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid currentColor;
            }
        }

        .sort-button-wrapper {
            height: 1.3em;
            display: flex;
            justify-content: center;
            aspect-ratio: 1;
            align-items: center;

            .sort-button {
                color: $color-darker-gray2;
                height: 100%;
                width: 100%;

                &.none {
                    color: $color-dark-gray;
                }
            }
        }
    }
}
