@import "../../../../../../utils/colors.utils.scss";

.safe-tab-container {
    height: 100%;
    display: grid;
    grid-template-columns: 20rem 1fr;
    grid-template-rows: 2.7rem 1fr;
    row-gap: 0.5rem;
    column-gap: 1rem;
    align-items: start;

    .top-right {
        button {
            &.selected_view_type {
                background-color: $color-light-pink;
            }
        }
    }

    .bottom-left {
        height: 100%;
        border-right: 1.5px solid $color-red;

        .data-wrapper {
            margin-top: 3vh;
        }
        .print-wrapper {
            // margin-top: 5vh;
            right: 0;
            left: min(2vw, 24px);
            bottom: 4vh;

            .button-wrapper {
                display: flex;
                gap: 1rem;

                > * {
                    flex: 1;
                    border: 1px solid currentColor;
                    padding: 0.3rem;
                    border-radius: 0.5rem;
                }
            }
        }
    }

    .bottom-right {
        .line {
            flex: 1;
            background-color: $color-text;
            height: 1px;
            margin-top: 1vh;
        }

        .header {
            display: flex;
            align-items: center;
            padding-bottom: 1vh;
            border-bottom: 1px solid $color-black;
            gap: 1vw;

            & > div {
                flex: 2;
            }

            .line {
                background-color: $color-red;
                margin: 0;
            }

            .text {
                font-size: min(1vw, 16px);
                padding: 10px;
                color: $color-red;
                font-weight: 500;

                .bold {
                    font-weight: 700;
                }
            }

            .button-wrapper {
                flex: 1;
                display: flex;
                gap: 1vw;
                // padding-inline: 2vw;

                .button {
                    cursor: pointer;
                    width: min(8vw, 96px);
                    height: min(4vh, 40px);
                    text-align: center;
                    border: 1px solid $color-black;
                    border-radius: min(2vh, 20px);
                    // margin-right: 20px;
                    line-height: min(4vh, 40px);
                    font-weight: 500;
                    font-size: min(1.6vh, 16px);
                }

                .selected {
                    background-color: $color-light-pink;
                }
            }
        }

        .table-wrapper {
            // background-color: $color-pink;
            flex: 1;
            margin-right: 3vw;
            margin-top: 2vh;
        }
    }
}

.balance-input {
    border: 2px solid transparent;
    border-radius: 5px;
    outline: none; /* remove the default focus outline */
    transition: border-color 0.2s ease-in-out;
    width: 40%;
    font-size: 16px;
    &:hover {
        border-color: #666;
    }
}

.sub-text {
    display: grid;
    grid-template-columns: 5fr 3fr;
    align-items: center;
    .bold {
        font-size: min(1.4vw, 14px);
    }
    .sub-title {
        font-size: min(1.4vw, 14px);
        font-weight: 600;
        padding-right: min(0.2vw, 5px);
        padding-bottom: 4px;
    }
    .info {
        font-size: min(1.4vw, 14px);
        font-weight: 400;
        display: flex;
        margin-right: 0.2vw;
        align-items: center;
        input {
            flex: 1;
            font-size: min(1.4vw, 14px);
        }
    }
}
