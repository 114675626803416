@import "../../utils/colors.utils.scss";

.file-selector-container {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    width: 100%;
    height: 8.5vh;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    backdrop-filter: blur(24px);
    padding-inline: 2vw;
    border-top: 1px solid $color-pink;

    .files-container {
        display: flex;
        width: 70%;
        gap: 20px;
        flex: 1;
        margin-inline: 2vw;
        overflow-x: scroll;
        // scrollbar-width: none;
    }
    .files-container::-webkit-scrollbar {
        // display: none;
        background-color: $gray-light;
        background-color: rgba(65, 60, 60, 0);
    }
    .files-container::-webkit-scrollbar-thumb {
        // display: none;
        background-color: $gray-default;
        border-radius: 12px;
    }

    #scroll-button {
        color: $color-background;
        font-size: 20px;
        border-radius: 20px;
    }
}
