@import "../../../utils/colors.utils.scss";

.invoice-container {
    position: relative;
    display: grid;
    grid-template-columns: min(30vw, 360px) 1fr;

    .left-panel {
        position: relative;
        background-color: $color-background;
        display: flex;
        flex-direction: column;
        padding-block: 1em;
        padding-left: min(2vw, 24px);
        border: 1px solid $color-black;
        border-block: 0;

        .calendar {
            // background-color: $color-pink;
            width: 100%;
            box-sizing: border-box;
            padding-right: 2vw;
            margin-top: 3vh;
        }

        .sub-text {
            padding-block: min(0.5vh, 5px);
            width: 100%;
            display: grid;
            grid-template-columns: 2fr 1fr;

            .sub-title {
                font-size: min(1.4vw, 14px);
                font-weight: 600;
                padding-right: min(0.2vw, 5px);
                padding-bottom: 4px;
            }
            .info {
                font-size: min(1.4vw, 14px);
                font-weight: 400;
                display: flex;
                align-items: center;
            }
            .balance-input {
                border: 2px solid transparent;
                border-radius: 5px;
                outline: none; /* remove the default focus outline */
                transition: border-color 0.2s ease-in-out;
                font-size: min(1.4vw, 14px);
                flex: 1;
                &:hover {
                    border-color: #666;
                }
                margin-right: 0.2vw;
            }
            .balance-input:focus {
                border-color: #666;
            }
            .balance-input::before {
                content: "€";
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .line {
            flex: 1;
            background-color: $color-text;
            height: 1px;
            margin: 0;
            align-items: center;
        }

        .data-wrapper {
            margin-top: 3vh;
        }
        .print-wrapper {
            // margin-top: 5vh;
            position: absolute;
            right: 0;
            left: min(2vw, 24px);
            bottom: 4vh;

            .button-wrapper {
                display: flex;

                .button {
                    cursor: pointer;
                    min-width: max-content;
                    padding-inline: 1vw;
                    width: min(8vw, 96px);
                    height: min(4vh, 40px);
                    text-align: center;
                    border: 1px solid $color-black;
                    border-radius: min(2vh, 20px);
                    margin-right: 20px;
                    line-height: min(4vh, 40px);
                    font-weight: 500;
                    font-size: min(1.6vh, 16px);
                }
            }
        }
    }

    .right-panel {
        display: flex;
        flex-direction: column;
        padding-block: 3vh;
        padding-left: 3vw;

        .line {
            flex: 1;
            background-color: $color-black;
            height: 1px;
            margin-top: 1vh;
        }

        .header {
            display: flex;
            align-items: center;
            padding-bottom: 1vh;
            border-bottom: 1px solid $color-black;

            .line {
                background-color: $color-red;
                margin: 0;
            }

            .text {
                font-size: min(1vw, 16px);
                padding: 10px;
                color: $color-red;
                font-weight: 500;

                .bold {
                    font-weight: 700;
                }
            }

            .button-wrapper {
                display: flex;
                gap: 1vw;
                padding-inline: 2vw;

                .button {
                    cursor: pointer;
                    padding-inline: 1vw;
                    padding-block: 0.1vh;
                    text-align: center;
                    border-radius: 1vh;
                    font-size: min(1.6vh, 16px);
                    font-weight: 500;
                    border: 1px solid $color-black;
                }

                .selected {
                    background-color: $color-gray;
                }
            }
        }

        .table-wrapper {
            // background-color: $color-pink;
            flex: 1;
            margin-right: 3vw;
            margin-top: 2vh;
        }
    }
}

.inactive-container {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    padding-left: 2vw;
    pointer-events: none;
    background: rgba($white, 0.7);
    backdrop-filter: blur(6px);

    .inactive {
        margin-top: 14vh;
        .text {
            font-size: 45px;
            font-weight: 600;
            border-bottom: 5px solid $color-red;
        }
    }
    .contact {
        margin-top: 2vh;
        font-size: 30px;
        font-weight: 600;
    }
}
