@import "../../../../../../../utils/colors.utils.scss";

.person-container--admin {
	// display: flex;
	// align-items: center;
	// gap: 1rem;
	// margin-right: 0.8rem;
	border-bottom: 1px solid $color-darker-gray;

	& > div {
		flex: 1;
		justify-content: flex-start;
		overflow: hidden;
		white-space: nowrap;

		&:nth-last-child(2) {
			flex: 0.6;
		}

		&:last-child {
			flex: 0.1;
		}

		& > span {
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.icon {
		font-size: 1.2em;
	}

	.image-wrapper {
		max-width: max-content;
		min-width: 2em;
		min-height: 2em;
		aspect-ratio: 1;
		border: 1px solid $color-black;
		border-radius: 1.5em;
		overflow: hidden;
	}

	.user-type {
		justify-self: flex-end;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 13ch;
		height: 2em;
		border-radius: 0.5em;
		border: 1px solid $color-black;
	}

	button {
		background-color: $color-gray;
		height: 2em;
		padding-inline: 1.15em;
		border-radius: 0.5em;
	}

	div:nth-of-type(3) {
		margin-left: auto;
		// width: 10em;
	}
}
