@import "../../utils/colors.utils.scss";

.file-container-wrapper {
	.file-container {
		cursor: pointer;
		background: rgba(255, 255, 255, 0.25);
		aspect-ratio: 7/2;
		height: 6vh;
		color: $color-background;
		line-height: 6vh;
		box-sizing: border-box;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-inline: 10px;
		border-radius: 0.8vh;
		border: 1px solid $color-background;
	}

	.selected {
		background-color: $color-dark-pink;
	}
}
