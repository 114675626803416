@import "../../../utils/colors.utils.scss";

.language-select {
    background-color: transparent;
    height: 4vh;
    aspect-ratio: 4;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1vh;
    z-index: 100;
    width: fit-content;
}
.language-text {
    font-size: 2vh;
    cursor: pointer;
}
.choosen {
    font-weight: 600;
}
.language-seperator {
    font-size: 1.6vh;
}
