$color-black: #000000;
$color-purple: #4632c1;
$color-darker-gray2: #686868;
$color-green: #74951e;
$color-light-green: #90e43c;
$color-darker-gray: #929292;
$color-light-red: #b52828;
$color-red: #bc0000;
$color-dark-lilac: #bdb2ff;
$color-dark-gray: #bdbdbd;
$color-gray: #d9d9d9;
$color-light-gray: #e8e8e8;
$color-lighter-gray: #f2f2f2;
$color-gray-white: #f5f5f5;
$color-lilac: #f5eeff;
$color-dark-pink: #fa7c7cb3;
$color-lighter-red: #ff7979;
$color-pink: #ffbfbf;
$color-light-pink: #ffe1e1;
$color-yellow: #ffedc0;
$color-white: #ffffff;

$color-background-bars: $color-gray-white;
$color-background: $color-white;
$color-foreground: #c20911;
$color-text: $color-black;

// #DA676C

// GRAYS:
$gray-default: #636b78;
$gray-light: #a1a6ad;
$gray-placeholder: #ccc;
$gray-lighter: #d1d4d6;
$white: #f5f5f5;
$black: #1b1b1b;

// LIGHT MODE:

$primary-red: #c20911;
$secondary-red: #da676c;
$dark-red: #6a0509;
$primary-shade: #edaeb1;
$secondary-shade: #f9ddde;
$hover: #f2e9ea;

// -----------------------------
// DARK MODE:

// $primary-red: #e40b14;
// $secondary-red: #ef6d72;
// $dark-red: #6b0408;
// $primary-shade: #f7b6b9;
// $secondary-shade: #fce7e8;
// $hover: #262626;
