@import "../../../../../../../../utils/colors.utils.scss";

.new-profile-modal-container {
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.modal {
		z-index: 2;
		background-color: $color-background;
		width: min(80%, 1000px);
		height: min(70%, 600px);

		.modal-header {
			border-bottom: 2px solid $color-foreground;
		}
	}
}
