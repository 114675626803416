@import "../../../../../../utils/colors.utils.scss";

.plans-container {
	.block {
		position: relative;
		&:not(.block:first-child) {
			margin-top: 1.2rem;
		}

		ul {
			padding-left: 1.5em;
			margin: 0;
			margin-bottom: 1.5em;

			li {
				margin-top: 0.5em;
			}
		}

		.selected-plan-container {
			position: absolute;
			right: 0;
			bottom: 1.5rem;

			background-color: $color-gray;
			padding: 0.2em 2em;
			border-radius: 0.5em;
		}
	}
}
