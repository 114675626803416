@import "../../../../utils/colors.utils.scss";

.folder-container-move-file {
	cursor: pointer;
	width: max-content;
	max-width: 24ch;
	white-space: nowrap;

	.folder {
		width: 2em;
		height: 2em;
		aspect-ratio: 1;
	}

	.folder-name {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
