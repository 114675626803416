@import "../../../../../utils/colors.utils.scss";

.ticket-details-container {
    height: 80vh;
}
.ticket-files {
    display: flex;
    flex-wrap: wrap;
    margin-block: 2.5em;
    .ticket-file {
        background-color: $primary-red;
        border-radius: 8px;
        padding: 0.8em 0.6em;
        max-height: 3em;
        min-width: 6em;
        overflow: hidden;
        color: $black;
        opacity: 0.8;
        max-width: 20%;
        &:hover {
            opacity: 1;
        }
        .download-img {
            height: 1.5em;
            width: 1.5em;
        }
        .ticket-filename {
            font-size: 0.9em;
            max-height: 90%;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
        }
    }
}
// copy ticket-file to download-all
.download-all {
    background-color: $dark-red;
    border-radius: 8px;
    padding: 0.8em 0.6em;
    max-height: 3em;
    min-width: 6em;
    overflow: hidden;
    color: $black;
    opacity: 0.9;
    max-width: 15%;
    &:hover {
        opacity: 1;
    }
    .download-img {
        height: 1.5em;
        width: 1.5em;
    }
    .ticket-filename {
        font-size: 0.9em;
        max-height: 90%;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        color: $white;
    }
}

.ticket-grid {
    display: grid;
    grid-template-columns: 6% 94%;
}
