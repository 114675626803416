@import "../../utils/colors.utils.scss";

.folder_container {
    $width: 6vw;

    width: $width;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    aspect-ratio: 1;
    overflow-wrap: break-word;
    text-align: center;

    .folder {
        width: $width * 0.8;
        height: $width * 0.8;
        aspect-ratio: 1;
    }

    .folder_name {
        background-color: $hover;
        width: $width;
        font-size: $width * 0.2;
        white-space: normal;
        text-align: center;
        resize: none;
        border: 0;

        &::-webkit-resizer {
            display: none;
        }

        /* width */
        &::-webkit-scrollbar {
            width: 0;
        }

        &:disabled {
            background-color: transparent;
            color: $color-text;
        }
    }

    &.small {
        $width: 4.5vw;
        width: $width;

        .folder {
            width: $width * 0.8;
            height: $width * 0.8;
            aspect-ratio: 1;
        }
        .folder_name {
            background-color: $hover;
            width: $width;
            font-size: $width * 0.2;
            white-space: normal;
            text-align: center;
            resize: none;
            border: 0;

            &::-webkit-resizer {
                display: none;
            }

            /* width */
            &::-webkit-scrollbar {
                width: 0;
            }

            &:disabled {
                background-color: transparent;
                color: $color-text;
            }
        }
    }
}
