@import "../../../utils/colors.utils.scss";

.profile-button {
    cursor: pointer;
    padding: 0.4vh min(0.55vw, 6.5px);
    display: flex;
    color: $color-black;
    align-items: center;
    column-gap: 0.5vw;
    border-radius: 3.2vh;
    border: 0.15rem solid $color-black;
    outline: none;

    .profile-picture {
        border: 0.2em solid transparent;
        height: 3vh;
        aspect-ratio: 1;
        border-radius: 50%;
        overflow: hidden;
    }

    img {
        object-fit: cover;
    }

    p {
        line-height: 100%;
        font-size: 2vh;
        margin: 0;
    }
}

@media (width < 700px) {
    .profile-button {
        display: none;
    }
}
