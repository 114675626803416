@import "../../utils/colors.utils.scss";

.calendar-container {
    box-sizing: border-box;
    font-size: 1rem;
    max-width: 400px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: min(2vh, 24px);
        padding-bottom: 1.6vh;

        .header-title {
            cursor: pointer;
            padding-right: 5px;
        }

        .disabled {
            pointer-events: none;
        }

        .right-side {
            display: flex;
            gap: min(1vw, 20px);
            user-select: none;
            .change-month-button {
                cursor: pointer;
                font-size: min(2vh, 20px);
            }
        }
    }

    .day-view-wrapper {
        .day-names {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            text-align: center;
            gap: min(0.5vw, 10px);
            font-size: max(0.8vw, 10px);
        }

        .week {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: min(0.5vw, 10px);
            text-align: center;
            font-weight: 500;
            font-size: 1.4vh;
            padding-top: 2vh;

            transition: margin-top 100ms;
            transition-timing-function: linear;

            // &:hover {
            // 	margin-top: 20px;
            // }

            .day {
                cursor: pointer;
                color: $color-black;
                display: flex;
                align-items: center;
                justify-content: center;
                aspect-ratio: 1;
                border-radius: min(0.6vw, 8px);
                font-size: max(0.8vw, 10px);
                font-weight: bold;
            }

            .other-month {
                color: $color-dark-gray;
            }

            .filled {
                background-color: $color-yellow;
            }

            .selected {
                border: 2px solid $color-black;
                background-color: $color-dark-lilac;
                color: $color-black;
                &.green {
                    background-color: $color-green;
                }
                &.red {
                    background-color: $secondary-red;
                }
            }

            .today {
                border: 1px solid $color-black;
            }
        }
    }

    .month-view-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: min(2vw, 10px);
        text-align: center;
        font-weight: 500;
        font-size: 1.4vh;

        .month {
            cursor: pointer;
            color: $color-black;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1;
            border-radius: min(0.6vw, 8px);
            font-size: max(1vw, 16px);
            font-weight: 500;
        }

        .locked {
            border: 2px solid $color-purple;
            color: $color-purple;
            justify-content: space-evenly;

            .lock-icon {
                font-size: max(1vw, 16px);
            }
        }

        .selected {
            border: 2px solid $color-black;
            color: $color-black;
            &.green {
                background-color: $color-green;
            }
            &.red {
                background-color: $secondary-red;
            }
        }
    }

    .year-view-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: min(2vw, 10px);
        text-align: center;
        font-weight: 500;
        font-size: 1.4vh;

        .year {
            cursor: pointer;
            color: $color-black;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1;
            border-radius: min(0.6vw, 8px);
            font-size: max(1vw, 16px);
            font-weight: 500;
        }

        .disabled {
            color: $color-dark-gray;
        }

        .locked {
            border: 2px solid $color-purple;
            color: $color-purple;
            justify-content: space-evenly;

            .lock-icon {
                font-size: max(1vw, 16px);
            }
        }

        .selected {
            border: 2px solid $color-black;
            color: $color-black;
            &.green {
                background-color: $color-green;
            }
            &.red {
                background-color: $secondary-red;
            }
        }
    }

    .footer {
        display: grid;
        grid-template-columns: 2.7fr 1.2fr;
        gap: 1vw;
        text-align: center;
        padding-top: 3vh;

        .box {
            cursor: pointer;
            min-height: min(3vh, 30px);
            font-size: min(1.5vh, 16px);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1.8px solid $color-black;
            border-radius: min(0.8vh, 8px);
        }

        .disabled {
            color: $color-darker-gray;
            border: 1.8px solid $color-darker-gray;

            pointer-events: none;
        }
    }
}

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 200ms linear;
}

/* enter */
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms linear 200ms;
}

/* exit */
.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms linear;
}

.fade-exit-done {
    opacity: 0;
}

/* slide enter */
.slide-enter {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    z-index: 1;
}
.slide-enter.slide-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: opacity 3000ms linear 1000ms, transform 3000ms ease-in-out 1000ms;
}

/* slide exit */
.slide-exit {
    opacity: 1;
    transform: scale(1) translateY(0);
}
.slide-exit.slide-exit-active {
    opacity: 0;
    transform: scale(0.97) translateY(5px);
    transition: opacity 1500ms linear, transform 1500ms ease-out;
}
.slide-exit-done {
    opacity: 0;
}
