@import "../../../utils/colors.utils.scss";

.account-container {
	display: grid;
	grid-template-columns: 12rem 1fr;

	.left-panel {
		background-color: $color-background;

		.list-container {
			margin-top: 3rem;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			&:first-of-type {
				margin-top: 1rem;
			}

			.buttons-container {
				$custom-padding: 0.5rem;
				position: relative;
				display: flex;
				flex-direction: column;
				left: -$custom-padding;
				padding-right: 1rem;

				.button {
					color: $color-black;
					font-size: 0.8rem;
					font-weight: 500;
					padding-block: 0.2em;
					padding-left: $custom-padding;
					border-radius: 0.5em;
					margin-block: 0.3em;
					outline: none;

					&:focus-visible {
						outline: 1px solid black;
					}
				}

				.selected {
					background-color: $color-pink;
				}

				.log-out {
					cursor: pointer;
					color: $color-red;
					text-align: left;

					&:focus-visible {
						outline: 1px solid black;
					}
				}
			}
		}
	}

	.right-panel {
		padding-left: 3em;
		margin-top: 1.3em;

		.data-wrapper {
			display: flex;
			flex-direction: column;
			// height: min-content;
			height: 80vh;
			// max-height: 80vh;
			padding-right: 1rem; //used padding for to be aligned scrollbar
			margin-right: 2rem;
			margin-top: 1.2rem;
			overflow: auto;

			.header {
				position: relative;
				font-size: 1.25rem;
				margin-bottom: 1.2rem;
				font-weight: 600;

				span {
					font-weight: 400;
				}

				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background: $color-black;
					margin-top: 0.2em;
				}
			}

			.fields {
				margin-top: 2em;
				display: flex;
				flex-direction: column;
				gap: 0.5em;

				.grid {
					display: grid;
					grid-template-columns: 15ch 1fr;

					.title {
						font-weight: 600;
					}
				}
			}
		}
	}
}
