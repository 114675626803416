@import "../../utils/colors.utils.scss";

.checkbox-container {
	cursor: pointer;

	.checkbox {
		cursor: pointer;

		appearance: none;
		background-color: $color-gray;
		margin-left: 0;
		font: inherit;
		color: currentColor;
		width: 1.15em;
		height: 1.15em;
		border: 0;
		border-radius: 0.3em;

		display: grid;
		place-content: center;

		&::before {
			content: "";
			width: 0.65em;
			height: 0.65em;
			transform: scale(0);
			transition: 60ms transform ease-in-out;
			box-shadow: inset 1em 1em $color-black;
			transform-origin: bottom left;
			clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
		}

		&:checked::before {
			transform: scale(1);
		}
	}
}
