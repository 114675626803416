@import "../../utils/colors.utils.scss";

.horizontal-selector {
    display: flex;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
}

.selector-list {
    border-radius: 15px;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1.8px solid $black;
    overflow: hidden;
    width: 100%;
}

.selector-item {
    flex: 1;
    position: relative;
    padding: 0.8vh 1vw;
    cursor: pointer;
    transition: transform 0.3s;
    &.selected {
        font-weight: bold;
    }
    text-align: center;
    font-size: 1.2vh;
    justify-content: center;
    align-items: center;
    border-right: 0.5px solid $black;
    border-left: 0.5px solid $black;
}

.background-color-element {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba($color-dark-pink, 0.5);
    transition: all 0.3s;
    z-index: -1;
}
