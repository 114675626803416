@import "../../utils/colors.utils.scss";

.round-image {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1em;
	font-weight: inherit;
	letter-spacing: 1px;
	border-radius: 50%;
}

.redish {
	background: linear-gradient(90deg, #ed4264 0%, #ffedbc 100%);
}

.yellowish {
	background: linear-gradient(90deg, #fbed96 0%, #abecd6 100%);
}

.bluish {
	background: linear-gradient(90deg, #b696fb 0%, #a3c6d1 100%);
}
