@import "../../../../../../../../utils/colors.utils.scss";

.plans-sub-category-container {
	.grid {
		display: grid;
		grid-template-columns: 1fr 16ch;
		align-items: center;
		border-bottom: 1px solid $color-text;
	}
}
