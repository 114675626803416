@import "../../../utils/colors.utils.scss";

.chat-container {
    position: relative;
    display: grid;
    grid-template-columns: min(30vw, 360px) 1fr;
    height: 100%;
    .left-panel {
        position: relative;
        height: 93vh;
        background-color: $color-background;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-bottom: 3vh;
        padding-left: min(2vw, 24px);
        border-right: 1px solid $color-black;
        overflow: auto;

        /* width */
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
            padding-inline: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #00000080;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .header {
            z-index: 1;
            position: sticky;
            top: 0;
            background-color: $color-gray;
            display: flex;
            align-items: center;
            padding-top: 1em;
            padding-bottom: 1vh;
            border-bottom: 1px solid $color-black;

            .title {
                font-size: 1.5em;
                color: $color-black;
                font-weight: 600;
            }
        }

        .chat-box-button-wrapper {
            padding-block: 1vh;
            padding-right: min(1vw, 10px);
            border-bottom: 1px solid $color-black;

            .chat-box-button {
                cursor: pointer;
            }
        }
    }
    .right-panel {
        display: flex;
        flex-direction: column;
        height: 93vh;
        padding-top: 1vh;

        .header {
        }

        .messages-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column-reverse;
            padding-inline: 5vw;
            overflow-y: auto;

            // hide scrollbar
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                /* Chrome */
                display: none;
            }
            .date-separator {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 1vh;
                padding-inline: 1vw;
                border-radius: 5px;
                font-size: 0.5em;
                font-weight: 600;
                color: $black;
                opacity: 0.5;
                &::before,
                &::after {
                    content: "";
                    flex: 1;
                    height: 1px;
                    background-color: $primary-red;
                    margin-inline: 0.5vw;
                }
            }
        }

        .message-input-wrapper {
            padding-inline: 5vw;
        }
    }
}
