@import "../../../utils/colors.utils.scss";

.clients-container {
    form {
        width: 40%;
        justify-content: space-between;
        padding-left: 0.5rem;
        border-bottom: 1px solid $color-text;

        input {
            width: 90%;
            padding-bottom: 0.2rem;
            border: none;
            outline: none;
        }
    }

    button {
        &.selected {
            background-color: $color-light-pink;
        }
    }
}

.tabs-wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-inline: 4rem;

    .selected-tab {
        color: $color-foreground;
        border-bottom: 2.1px solid currentColor;
    }
    span {
        // &.selected-tab {
        // 	color: $color-foreground;
        // 	border-bottom: 2px solid currentColor;
        // }
    }
}
