@import "../../../../../utils/colors.utils.scss";

.invoice-table-container {
    width: 100%;

    .invoice-table {
        width: 96%;
        border-spacing: 0;

        tbody {
            // width: 100%;
            width: calc(100% + 20px);
            max-height: 62vh;
            display: block;
            overflow-y: auto;
            overflow-x: hidden;

            & > * {
                margin-right: 12px;
            }

            /* width */
            &::-webkit-scrollbar {
                width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
                padding-inline: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #00000080;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .last-child {
                border-bottom: 0;
            }
        }

        .table-row {
            height: max(24px, min(36px, 2.4vw));
            justify-content: space-between;
            display: grid;
            grid-template-columns: repeat(5, 5fr) 1fr;
            font-size: max(12px, min(18px, 2vw));
            font-weight: 500;
            line-height: max(24px, min(36px, 2.4vw));
            text-align: left;
            padding-inline: 10px;
            border-bottom: 1.2px solid $color-black;

            th {
                font-weight: 500;
                border-bottom: none;
            }

            .remove-button {
                cursor: pointer;
                font-size: max(10px, min(16px, 1.6vw));
            }

            .Revenue {
                color: $color-green;
            }

            .Expense {
                color: $color-light-red;
            }

            input:disabled {
                background-color: $color-darker-gray;
                border-radius: 2px;
            }
        }

        .new-row {
            color: $color-darker-gray;
            line-height: max(24px, min(36px, 2.4vw));

            .date-picker {
                background-color: transparent;
                color: $color-darker-gray;
                width: 80%;
                height: max(12px, min(18px, 2vw));
                font-size: max(12px, min(18px, 2vw));
                text-align: left;
                padding: 0;
                border: 0;
            }

            .new-row-input {
                height: max(12px, min(18px, 2vw));
                width: 80%;
                color: $color-darker-gray;
                font-size: max(12px, min(18px, 2vw));
                padding: 0;
                border: none;
                outline: none;

                &::placeholder {
                    color: $color-gray;
                }
            }
        }

        .positive {
            color: $color-green;
        }

        .negative {
            color: $color-light-red;
        }
    }

    .new-file-button {
        width: 96%;
        background-color: $color-lilac;
        height: max(24px, min(36px, 2.4vw));
        font-size: max(12px, min(18px, 2vw));
        line-height: max(24px, min(36px, 2.4vw));
        font-weight: 500;
        border-top: 1px solid $color-black;

        .text {
            cursor: pointer;
            height: 100%;
            padding-inline: 10px;
        }
    }

    .save-button-container {
        width: 96%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .save-button {
            background-color: transparent;
            height: 36px;
            font-size: 16px;
            padding-inline: 30px;
            border-radius: 20px;
            border: 1px solid $color-black;
            color: $color-black;
        }

        .disabled {
            border-color: $color-darker-gray;
            color: $color-darker-gray;
        }
    }
}
