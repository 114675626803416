@import "./utils/colors.utils.scss";

body {
    margin: 0;
    background-color: $color-background;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: hidden;

    color: $color-text;
}

html {
    //NOTE: on figma 20px is == 1rem
    // font-size: clamp(0.5rem, 0.5rem + 1vw, 1.25rem);
    font-size: 16px;
}

.route-container {
    padding-left: 3em;
    margin-top: 1.3em;
}

button {
    cursor: pointer;
    background-color: transparent;
    font-family: "Inter", sans-serif;
    border: none;
}

.btn {
    border: 1px solid currentColor;
    border-radius: 0.5em;

    &--red {
        border: 0;
        background-color: $color-foreground;
        color: $color-background;
    }

    &--red-text {
        color: $color-foreground;
    }

    &--red-border {
        border-color: $color-foreground;
    }

    &--hover {
        &:hover {
            background-color: $color-lighter-gray;
        }
        &-dark:hover {
            background-color: $color-dark-gray;
        }
    }

    &--disabled {
        color: $color-dark-gray;
        cursor: default;

        &.btn--hover:hover {
            background-color: transparent;
        }
    }
}

.text {
    &-left {
        text-align: left;
    }
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
}

h1 {
    font-size: 1.875rem;
    font-weight: 500;
    margin: 0;
    padding: 0;

    &.bold {
        font-weight: 600;
    }
}

h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    padding: 0;

    &.bold {
        font-weight: 600;
    }
}

h3 {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    padding: 0;

    &.bold {
        font-weight: 500;
    }

    &.bolder {
        font-weight: 600;
    }
}

p {
    margin: 0;
}

div,
input {
    box-sizing: border-box;
}

.size {
    &100 {
        // ~8px
        font-size: 0.512rem;
    }
    &200 {
        // ~10px
        font-size: 0.64rem;
    }
    &300 {
        // ~12px
        font-size: 0.8rem;
    }
    &350 {
        // ~14px
        font-size: 0.9rem;
    }
    &400 {
        // ~16px
        font-size: 1rem;
    }
    &500 {
        // ~20px
        font-size: 1.25rem;
    }
    &600 {
        // ~25px
        font-size: 1.563rem;
    }
    &700 {
        // ~31px
        font-size: 1.953rem;
    }
    &800 {
        // ~39px
        font-size: 2.441rem;
    }
    &900 {
        // ~48px
        font-size: 3.052rem;
    }
    &1000 {
        // ~61px
        font-size: 3.815rem;
    }
    &1100 {
        // ~76px
        font-size: 4.768rem;
    }
}

.w {
    &100 {
        font-weight: 100;
    }
    &200 {
        font-weight: 200;
    }
    &300 {
        font-weight: 300;
    }
    &400 {
        font-weight: 400;
    }
    &500 {
        font-weight: 500;
    }
    &600 {
        font-weight: 600;
    }
    &700 {
        font-weight: 700;
    }
    &800 {
        font-weight: 800;
    }
    &900 {
        font-weight: 900;
    }
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;

    &.fill {
        inset: 0;
    }
}

.padding {
    &-auto {
        $padding: auto;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }

    &-xs {
        $padding: 0.2em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-s {
        $padding: 0.5em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-m {
        $padding: 0.8em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-l {
        $padding: 1.2em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-xl {
        $padding: 1.5em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-xxl {
        $padding: 2em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-xxxl {
        $padding: 2.4em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-xxxxl {
        $padding: 2.7em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-xxxxxl {
        $padding: 3em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
    &-xxxxxxl {
        $padding: 3.5em;
        padding: $padding;

        &--top {
            padding-top: $padding;
        }

        &--bottom {
            padding-bottom: $padding;
        }

        &--right {
            padding-right: $padding;
        }

        &--left {
            padding-left: $padding;
        }

        &--block {
            padding-block: $padding;
        }

        &--inline {
            padding-inline: $padding;
        }
    }
}

.margin {
    &-auto {
        $margin: auto;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-xs {
        $margin: 0.2em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-s {
        $margin: 0.5em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-m {
        $margin: 0.8em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-l {
        $margin: 1.2em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-xl {
        $margin: 1.5em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-xxl {
        $margin: 2em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-xxxl {
        $margin: 2.4em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
    &-xxxxl {
        $margin: 3em;
        margin: $margin;

        &--top {
            margin-top: $margin;
        }

        &--bottom {
            margin-bottom: $margin;
        }

        &--right {
            margin-right: $margin;
        }

        &--left {
            margin-left: $margin;
        }

        &--block {
            margin-block: $margin;
        }

        &--inline {
            margin-inline: $margin;
        }
    }
}

.border {
    border: 1px solid currentColor;

    &--bottom {
        border: none;
        border-bottom: 1px solid currentColor;
    }
    &--right {
        border: none;
        border-right: 1px solid currentColor;
    }
    &--top {
        border: none;
        border-top: 1px solid currentColor;
    }
    &--left {
        border: none;
        border-left: 1px solid currentColor;
    }

    &--thick {
        border-width: 2px;
    }
    &--thicker {
        border-width: 3px;
    }

    &--red {
        border-color: $color-foreground;
    }
}
.red {
    color: $color-foreground;
}
.green {
    color: $color-green;
}
.black {
    color: $color-text;
}
.white {
    color: $color-white;
}

.with-line::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color-text;
    margin-top: 0.3rem;
}

.smooth-corners {
    border-radius: 0.5em;
}
.round-corners {
    border-radius: 5rem;
}
.semi-round-corners {
    border-radius: 2.2rem;
}

.left-panel {
    position: relative;
    background-color: $color-gray;
    display: flex;
    flex-direction: column;
    padding-block: 1em;
    padding-left: min(2vw, 24px);
    border-block: 0;
    border-right: 1px solid $color-black;
}

.unscrollable {
    overflow: hidden;
}

.unselectable {
    user-select: none;
}

.scroll-bar {
    &--overflow {
        overflow: auto;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #00000080;
        border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    //for firefox
    scrollbar-color: #555 transparent;
    scrollbar-width: thin;
}

a {
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}

.flex {
    display: flex;

    &--column {
        flex-direction: column;

        // &.gap-m {
        // 	gap: 2rem;
        // }
    }

    &--grow {
        flex-grow: 1;
    }

    &--space-between-content {
        justify-content: space-between;
    }
    &--space-between-items {
        align-items: space-between;
    }

    &--center {
        justify-content: center;
        align-items: center;
    }
    &--center-content {
        justify-content: center;
    }
    &--center-items {
        align-items: center;
    }

    &--end {
        justify-content: flex-end;
        align-items: flex-end;
    }
    &--end-content {
        justify-content: flex-end;
    }
    &--end-items {
        align-items: flex-end;
    }

    &--start {
        justify-content: flex-start;
        align-items: flex-start;
    }
    &--start-content {
        justify-content: flex-start;
    }
    &--start-items {
        align-items: flex-start;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &1 {
        flex: 1;
    }
    &2 {
        flex: 2;
    }
    &3 {
        flex: 3;
    }
}

.gap {
    &-xs {
        gap: 0.3rem;
    }
    &-s {
        gap: 0.6rem;
    }
    &-m {
        gap: 1rem;
    }
    &-l {
        gap: 1.2rem;
    }
    &-xl {
        gap: 1.5rem;
    }
    &-xxl {
        gap: 2rem;
    }
    &-xxxl {
        gap: 3rem;
    }
    &-xxxxl {
        gap: 4rem;
    }
}

.color-picker {
    position: relative;
    height: 2rem;
    aspect-ratio: 1;
    border-radius: 0.5rem;
    border: 1px solid $color-text;

    &.selected-color::after {
        content: "";
        position: absolute;
        inset: 0;
        translate: 60% 65%;
        // background-color: blue;
        width: 0.8rem;
        height: 0.8rem;
        box-shadow: inset 1em 1em currentColor;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
}

.width {
    &-auto {
        width: auto;
    }
    &10 {
        width: 10%;
    }
    &20 {
        width: 20%;
    }
    &30 {
        width: 30%;
    }
    &40 {
        width: 40%;
    }
    &50 {
        width: 50%;
    }
    &60 {
        width: 60%;
    }
    &70 {
        width: 70%;
    }
    &80 {
        width: 80%;
    }
    &90 {
        width: 90%;
    }
    &100 {
        width: 100%;
    }
}

.height {
    &-auto {
        height: auto;
    }
    &10 {
        height: 10%;
    }
    &20 {
        height: 20%;
    }
    &30 {
        height: 30%;
    }
    &40 {
        height: 40%;
    }
    &50 {
        height: 50%;
    }
    &60 {
        height: 60%;
    }
    &70 {
        height: 70%;
    }
    &80 {
        height: 80%;
    }
    &90 {
        height: 90%;
    }
    &100 {
        height: 100%;
    }
}

.invisible {
    visibility: hidden;
}

.contain {
    object-fit: contain;
}

.zindex10 {
    z-index: 10;
}

.overflow {
    &-hidden {
        overflow: hidden;
    }
    &-visible {
        overflow: visible;
    }
    &-scroll {
        overflow: scroll;
    }
}

.onhover {
    &:hover {
        background-color: $color-lighter-gray;
    }
}

.display-none {
    display: none;
}

.hide-bar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        /* Chrome */
        display: none;
    }
}
