@import "../../../../../../../../utils/colors.utils.scss";

$height: 1.6rem;
$padding: 0;
$padding: 0.2rem;
$aspect-ratio: 1.6;
$gap: 0.2rem;

.toggle-tab-container {
	cursor: pointer;
	position: relative;
	display: grid;
	grid-template-columns: 5rem 5rem;
	gap: $gap;
	text-align: center;
	align-items: center;
	background-color: $color-text;
	height: $height;
	border-radius: $height / 3;
	// outline: 1px solid $color-text;
	overflow: hidden;

	.toggle {
		position: absolute;
		background-color: $color-background;
		height: 100%;
		width: 5rem;
		left: 0;
		transition: transform 300ms ease;
		border: $padding solid $color-text;
		border-radius: $height/3;

		&.checked {
			transform: translateX(calc(5rem + $gap));
		}
	}

	.toggle-text {
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		color: $color-gray;

		&.selected {
			color: $color-text;
		}
	}
}
