.dropdown {
    position: relative;
}

.dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    // if width overflows, show text with ...
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    min-width: 200px;
    padding: 6px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.search-input {
    width: 15rem;
    height: 1.8rem;
    border-radius: 0.3rem;
    border: none;
    border-bottom: 1.6px solid rgba(0, 0, 0, 0.5);
}

.search-icon {
    height: 1.2rem;
    width: auto;
}
