@import "../../../../../../utils/colors.utils.scss";

.settings-tab-container {
	height: 100%;
	display: grid;
	grid-template-columns: 20ch 1fr;

	.left {
		// background-color: blue;
		border-right: 1.6px solid $color-red;

		.buttons-container {
			$custom-padding: 0.5rem;
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			button {
				color: $color-black;
				font-size: 0.8rem;
				text-align: left;
				padding-block: 0.2em;
				padding-left: $custom-padding;
				border-radius: 0.5em;
				margin-right: 1rem;
				outline: none;

				&:focus-visible {
					outline: 1px solid black;
				}
				&.selected {
					background-color: $color-pink;
				}

				&.delete {
					color: $color-red;

					&:focus-visible {
						outline: 1px solid black;
					}
				}
			}
		}
	}
	.right {
	}
}
