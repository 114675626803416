@import "../../../../../../utils/colors.utils.scss";

.settings-container {
    .block {
        &:not(.block:first-child) {
            margin-top: 1.6rem;
        }

        .grid {
            display: grid;
            grid-template-columns: 12ch 1fr;
        }

        .inputs {
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            button {
                background-color: $color-light-pink;
                max-width: fit-content;
                padding: 0.3em 1.2em;
                border: 1px solid $color-black;
                border-radius: 1rem;
            }
        }
    }
}
