@import "../../../../../utils/colors.utils.scss";

.new-ticket-modal-container {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal {
        z-index: 2;
        background-color: $color-background;
        width: min(80%, 1200px);
        // height: min(80%, 700px);

        .modal-header {
            border-bottom: 2px solid $color-foreground;
        }
    }
}
