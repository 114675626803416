@import "../../../utils/colors.utils.scss";

.home-container {
    position: relative;
    display: grid;
    grid-template-columns: 15rem 1fr;
    height: 92vh;

    .left-panel {
        background-color: $color-background;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-block: 1em;

        border-right: 1px solid $color-black;

        .header-container {
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 10px;
            padding-left: min(2vw, 30px);

            .header-title {
                font-size: 1.5rem;
                font-weight: 600;
                padding-bottom: 10px;
                border-bottom: 1px solid $color-black;
            }
        }

        .sidebar-folders-container {
            width: 90%;
            max-height: 70vh;
            font-size: 0.8rem;
            margin-bottom: 10px;
            overflow: auto;
        }

        .create-folder-button {
            cursor: pointer;
            font-size: 0.8rem;
            padding-block: 0.4em;
            padding-inline: 1.2em;
            border: 1px solid $color-black;
            border-radius: 1em;
            margin-block: auto 1em;
            bottom: 40px;
        }
    }

    .right-panel {
        // height: 60%;
        padding: 2% 2% 1%;

        .header-group {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-block: 1vw;
            border-bottom: 1px solid $color-black;
            margin-bottom: 3vh;

            .home-header {
                width: 90%;
                display: flex;
                align-items: center;
                gap: 20px;

                ion-icon[name="arrow-back-outline"] {
                    font-size: min(2.7vw, 30px);
                    cursor: pointer;
                }

                .breadcrumbs {
                    font-size: min(2.1vw, 21px);
                    font-weight: 500;
                }
            }
            .lock-btn {
                width: 2.5vh;
                height: auto;
                // object-fit: contain;
            }
        }

        .folders-container {
            // min-height: 12vh;
        }
    }
}

.selector {
    padding: 5vh 0px;
}
