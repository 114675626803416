@import "../../../utils/colors.utils.scss";

.row-title {
    width: 90vw;
    position: relative;

    cursor: pointer;
    padding-left: 0.5rem;

    .id-input {
        border: 2px solid transparent;
        border-radius: 5px;
        outline: none; /* remove the default focus outline */
        transition: border-color 0.2s ease-in-out;
        width: 80%;
        &:hover {
            border-color: #666;
        }
        &:focus {
            border-color: #666;
        }
    }

    .img-box {
        display: inline-block;
        height: 1.5em;
        aspect-ratio: 1;
        border-radius: 0.3em;
    }

    &.expanded-title {
        background-color: #f2e9ea;

        &::after {
            content: "";
            position: absolute;
            left: 0px;
            height: 100%;
            border-left: 4px solid $color-foreground;
        }
    }

    .fillers {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .filler-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .icon {
                height: 1rem;
                width: 1rem;
                font-size: 1rem;
            }

            span {
                background-color: $gray-lighter;
                padding-inline: 0.5em;
                border-radius: 0.4em;
            }
        }
    }

    &:hover,
    &:focus-visible {
        background-color: #f2e9ea;
    }
}

.row-data {
    width: 90vw;
    grid-template-columns: 1fr !important;
    align-items: flex-start;
    height: 0;
    transform: scale(0);
    transform-origin: 0;
    transition: transform 200ms ease-in-out, height 200ms ease-in-out;
    padding-block: 0 !important;
    border-color: $color-foreground !important;
    overflow: hidden;

    &.expanded {
        position: relative;
        height: 37rem;
        // height: fit-content;
        transform: scale(1);

        &::after {
            content: "";
            position: absolute;
            left: 0px;
            height: 100%;
            border-left: 4px solid $color-foreground;
        }
    }
}
